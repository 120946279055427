import React from "react";

import * as Scroll from 'react-scroll';
import { useLocation, useNavigate } from "react-router-dom";

import footerData from "./DataFooter.json";
import socialMediaData from "./DataSocialMedia.json";

import {
  ContainerFooter,
  BoxSocialMedia,
  BoxInformation,
  Row,
  Column,
  FooterInformation,
  Heading,
} from "./styles";

const Footer = () => {

  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const scrollToAnchor = (scrollTo) => {
    scroller.scrollTo(scrollTo, {
      duration: 2000,
      smooth: true,
    });
  };

  const goToPageAndScroll = async (to, scrollTo) => {
    await navigate(to);
    await scroller.scrollTo(scrollTo, {
      duration: 2000,
      smooth: true,
    });
  };


  return (
    <ContainerFooter>
      <BoxSocialMedia>
        <h1>InnovaCode</h1>

        <div className="description-innovacode">
          <p id="first-p">Transformando Desafios em</p>
          <p id="last-p">Oportunidades com Soluções Inteligentes</p>
        </div>
        <div className="social-media-content">
          {socialMediaData.map((media) => (
            <a
              key={media.id}
              href={media.link}
              rel="noreferrer"
              target="_blank"
            >
              <img
                alt={media.alt}
                src={require(`../../assets/socialMedia/${media.icon}`)}
              />
            </a>
          ))}
        </div>
      </BoxSocialMedia>
      <BoxInformation>
        <Row>
          {footerData.map((data) => (
            <Column key={data.id}>
              <Heading>{data.title}</Heading>
              {data.links.map((elem) => 
                { return (location === data.location) ? (
                  <FooterInformation 
                    key={elem.id}
                    onClick={() => scrollToAnchor(elem.link)}
                    className={elem.link ? "as-link": ""}
                  >
                     {elem.icon && (
                       <img
                         className="icon-footer-information"
                         alt={elem.icon_alt}
                         src={require(`../../assets/${elem.icon}`)}
                       />
                     )}
                     <span>{elem.content}</span>
                   </FooterInformation>
                ) : ( 
                  <FooterInformation 
                    key={elem.id}
                    onClick={() => goToPageAndScroll(data.location, elem.link)}
                    className={elem.link ? "as-link": ""}
                  >
                  {elem.icon && (
                    <img
                      className="icon-footer-information"
                      alt={elem.icon_alt}
                      src={require(`../../assets/${elem.icon}`)}
                    />
                  )}
                  <span>{elem.content}</span>
                  </FooterInformation>
                )}
              )}
            </Column>
          ))}
        </Row>
      </BoxInformation>
    </ContainerFooter>
  );
};
export default Footer;


