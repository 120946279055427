import styled from "styled-components";

export const ContainerFooter = styled.div`
  padding: 5% 5%;
  background: #111036;
  width: 100%;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const BoxSocialMedia = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;

  h1 {
    color: var(--main);
    font-size: 2.5rem;
  }

  .description-innovacode #first-p {
    padding-top: 2rem;
    color: var(--main);
  }

  .description-innovacode #last-p {
    color: var(--main);
    padding-top: 1.4rem;
    padding-bottom: 2rem;
  }

  .social-media-content {
    display: flex;
    gap: 30px;

    a {
      transition: 0.2s ease;
      &:hover {
        filter: brightness(0.7);
      }

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  @media (max-width: 800px) {
    .social-media-content {
      padding-bottom: 3rem;
    }
  }
`;

export const BoxInformation = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 4fr;
  gap: 20px;

  @media (max-width: 1100px) {
    padding-left: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 900px) {
    padding-left: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }

  @media (max-width: 800px) {
    padding-left: 0;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const FooterInformation = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  text-decoration: none;

  &.as-link {
    cursor: pointer; 
  }

  span {
    color: var(--main);
    font-size: 1.1rem;
    word-wrap: break-word;
    color: #a6a0c4;
  }

  .icon-footer-information {
    width: 42px;
    height: 42px;
  }

  img {
    padding-right: 10px;
  }

  &:hover {
    color: var(--text-boddy);
    transition: 200ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 1.8rem;
  color: var(--main);
  margin-bottom: 2rem;
  font-weight: bold;
`;
