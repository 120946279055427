import styled from "styled-components";

export const ContaineSolutions = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--bg-blue);
  height: 580px;
  width: 100%;

  h1 {
    font-size: 2.5rem;
    color: var(--main);
    padding-top: 3rem;
    margin-bottom: 1rem;
  }

  p.info {
    font-size: 1.1rem;
    color: var(--main);
    margin-bottom: 3rem;
  }

  @media (max-width: 1860px) {
    height: 900px;
  }

  @media (max-width: 1180px) {
    height: 1250px;
  }

  
  @media (max-width: 790px) {
    h1 {
      font-size: 1.8rem;
    }
  }
  @media (max-width: 757px) {
    height: 1920px;
  }

  @media (max-width: 547px) {
    h1 {
      font-size: 1.6rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 80px;

  .icon-card {
    margin-top: 2rem;
    margin-bottom: 20px;
    font-size: 2rem;
  }

  @media (max-width: 840px) {
    margin: 0 40px;
  }

  @media (max-width: 500px) {
    margin: 0 40px;
  }
`;

export const Card = styled.div`
  width: 320px;
  height: 300px;
  background: var(--main);
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  padding: 0 20px;

  transition: 0.2s ease;

  &:hover {
    transform: scale(1.03);
  }

  h4 {
    margin-bottom: 1rem;
    text-align: center;
  }

  p .description {
    align-items: center;
  }
`;
