import { Box, Button, Select, TextField } from "@mui/material";
import styled from "styled-components";

export const ContainerSuportForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg-blue);
  padding: 3rem 0rem;
  width: 100%;
  
  .content-title-suport {
    text-align: center;
    width: 50%;
    h1 {
      font-size: 2.5rem;
      color: var(--main);
      
      margin-bottom: 1rem;
    }

    p.info {
      font-size: 1.1rem;
      color: var(--main);
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 950px) {
    .content-title-suport {
      width: 85%;
      h1 {
        font-size: 2.3rem;
      }
      p.info {
        font-size: 1.05rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .content-title-suport {
      width: 90%;
      h1 {
        font-size: 2rem;
      }
      p.info {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .content-title-suport {
      width: 95%;
      h1 {
        font-size: 1.5rem;
      }
      p.info {
        font-size: 0.9rem;
      }
    }
  }
`;

export const ContentSuportForm = styled.div`
  display: flex;
  width: 520px;
  text-align: center;
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 85%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

export const CustomBox = styled(Box)`
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const CustomTextField = styled(TextField)`
  && {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex: 1; 
    margin-right: 10px;
  }
`;

export const CustomSelectSuport = styled(Select)`
  text-align: left;
  margin-bottom: 10px;
  flex: 1;

  && {
    width: 100%;
    &.MuiInputBase-root {
      background-color: white;
      &:hover {
        background-color: white;
      }
      &:focus {
        background-color: white;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-left: 0px;
  }
` 

export const CustomButton = styled(Button)`
  && {
    background: linear-gradient(45deg, #1a8ce9 30%, #21CBF3 90%); 
    border-radius: 3px;
    border: 0;
    color: white;
    height: 48px;
    padding: 0 30px;
    box-shadow: 0 3px 5px 2px rgba(33, 203, 243, 0.3); 
  }
`;
