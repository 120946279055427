import React from "react";

import { ContainerHero, ContentHero } from "./styles";

const Hero = ({ backgroundImage, title, description }) => {
  return (
    <ContainerHero>
      <img src={backgroundImage} alt="Imagem principal" />
      <ContentHero>
        <h1>{title}</h1>
        <p>{description}</p>
      </ContentHero>
    </ContainerHero>
  );
};

export default Hero;
