import React from "react";
import Hero from "../../components/Hero";
import { Element } from 'react-scroll';

import aboutImg from "../../assets/img/info-innovacode-hero.jpg";
import mission from "../../assets/img/mission.jpg";
import values from "../../assets/img/values.jpg";
import vision from "../../assets/img/vision.jpg";

import {
  AboutUs,
  ContainerAbout,
  ContainerMain,
  ContainerSection,
  ContentMission,
  ContentValues,
  ContentVision,
  Mission,
  Values,
  Vision,
  StyledLink,
  ContainerAboutUs
} from "./styles";




const About = () => {
  return (

    <ContainerAbout>
    <StyledLink to="/sobre">
        <Hero
          backgroundImage={aboutImg}
          title="Conheça a InnovaCode"
          description={
            "Buscamos desenvolver soluções que sejam responsáveis e sustentáveis"
          }
        />

        <ContainerMain>
          <h1>Nossa missão, visão e valores</h1>
          <ContainerSection>
            <Element name="section-mission">

            <Mission>
              <img src={mission} alt="Missão" />
              <ContentMission>
                <h2>Nossa missão</h2>
                <p>
                  Na InnovaCode, nossa missão é impulsionar a inovação por meio
                  da tecnologia, desenvolvendo soluções de software de ponta,
                  acessíveis e sustentáveis para atender às necessidades de
                  nossos clientes.
                </p>
              </ContentMission>
            </Mission>
            </Element>

            <Element name="section-vision">

            <Vision>
              <img src={vision} alt="Visão" />

              <ContentVision>
                <h2>Nossa visão</h2>
                <p>
                  Nossa visão na InnovaCode é liderar a transformação digital,
                  capacitando empresas com soluções inovadoras, acessíveis e
                  respeitosas com o meio ambiente.
                </p>
              </ContentVision>
            </Vision>
            </Element>

            <Element name="section-values">
            <Values>
              <img id="value-img" src={values} alt="Valores" />
              <ContentValues>
                <h2>Nossos valores</h2>
                <ol>
                  <li>
                    Inovação Acessível: Promovemos a inovação constante com foco
                    na acessibilidade.
                  </li>
                  <li>
                    Respeito Mútuo: Valorizamos o respeito mútuo em todas as
                    nossas relações.
                  </li>
                  <li>
                    Futuro Sustentável: Buscamos soluções que atendam às
                    expectativas dos clientes e contribuam para um futuro
                    sustentável.
                  </li>
                </ol>
              </ContentValues>
            </Values>
            </Element>
          </ContainerSection>

          <ContainerAboutUs>

          <AboutUs>
            <h1>Sobre Nós</h1>
            <div className="content-aboutUs">

            <p>
              Na InnovaCode, somos mais do que uma startup de tecnologia. Somos
              uma equipe apaixonada e comprometida com a inovação, o respeito ao
              próximo e o meio ambiente. Nossa jornada começou com a visão de
              liderar a transformação digital, criando soluções de software de
              ponta. O que nos diferencia é o nosso compromisso em tornar a
              tecnologia acessível a todos, promover relações baseadas no
              respeito mútuo e agir de maneira responsável em relação ao meio
              ambiente.
            </p>

            <p>
              Nossos valores de inovação constante, excelência técnica, parceria
              e colaboração, integridade e ética, bem como um foco no cliente e
              em um futuro sustentável, impulsionam cada aspecto de nossa
              empresa. Estamos comprometidos em criar um impacto positivo no
              mundo por meio da tecnologia e em ajudar nossos clientes a
              alcançarem seus objetivos com soluções personalizadas e
              sustentáveis.
            </p>

            <p>
              Junte-se a nós nesta jornada de transformação digital, onde a
              inovação, o respeito e a sustentabilidade são os pilares de nossa
              atuação. Conheça mais sobre a InnovaCode e descubra como podemos
              ser seu parceiro confiável na busca de soluções de software
              inovadoras e responsáveis.
            </p>
            </div>
          </AboutUs>
            </ContainerAboutUs>  

        </ContainerMain>
    </StyledLink>
    </ContainerAbout>
  );
};

export default About;
