import RoutesApp from "./routes/routes";
import { ToastContainer } from "react-toastify"

import { Global } from "../src/Styles/global";

function App() {
  return (
    <div className="App">
       < RoutesApp /> 
       <ToastContainer />
        <Global />
    </div>
  );
}

export default App;
