import React from 'react';
import { ContainerSectionFocus, Content, ContentFocus, ContentImage } from './styles';

import shield from "../../assets/shield-check.svg";
import light from "../../assets/light.svg";
import lightning from "../../assets/lightning.svg";

const SectionFocus = () => {
  return (
    <ContainerSectionFocus>
        <Content>
            <h1>
                Por que escolher a InnovaCode?
            </h1>
            <ContentFocus>
                <ContentImage>
                    <img src={shield} alt="Segurança" />
                    <h2>
                        Segurança
                    </h2>
                    <p>
                        A segurança da informação é nossa prioridade, 
                        mantemos suas informações confidenciais e protegidas.
                    </p>
                </ContentImage>
                <ContentImage>
                    <img src={light} alt="Inovação" />
                    <h2>
                        Soluções Inovadoras
                    </h2>
                    <p>
                        Oferecemos um mundo de possibilidades 
                        com soluções inovadoras que transformam 
                        o seu negócio.
                    </p>
                </ContentImage>
                <ContentImage>
                    <img src={lightning} alt="Agilidade" />
                    <h2>
                        Agilidade
                    </h2>
                    <p>
                        Nossa metodologia ágil garante que os resultados 
                        sejam entregues com agilidade e excelência.
                    </p>
                </ContentImage>
            </ContentFocus>
        </Content>
    </ContainerSectionFocus>
  )
}

export default SectionFocus