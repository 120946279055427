import styled from "styled-components";

export const ContainerMain = styled.section`
  height: 400px;
  background: var(--bg-blue-light);

`;


export const Container = styled.div`
  margin: 0 80px;
  background: var(--bg-blue-light);

  h1 {
    text-align: center;
    color: var(--main);
    margin-bottom: 1rem;
    padding-top: 3rem;
    font-size: 2.3rem;
  }

  p {
    text-align: center;
    color: var(--main);
    margin-bottom: 3rem;
    font-size: 1.1rem;
  }

  img {
    &.custom-slide {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }

  @media (max-width: 768px) {
    margin: 0 40px;
  }

  @media (max-width: 470px) {
    margin: 0 30px;
  }
  @media (max-width: 790px) {
    h1 {
      font-size: 1.8rem;
    }
  }
  @media (max-width: 547px) {
    h1 {
      font-size: 1.6rem;
    }
  }

`;
