import styled from "styled-components";

export const ContentPortfolio = styled.section`
  display: flex;
  flex-direction: column;
  background: var(--bg-blue);
  padding-top: 3rem;
  padding-bottom: 3rem;

  div {
    h1 {
      text-align: center;
      font-size: 2.3rem;
      color: var(--main);
    }

    .preview {
      color: var(--main);
      margin: 15px;
      font-size: 1.1rem;
      text-align: center;
      padding-bottom: 3rem;
    }

    @media (max-width: 790px) {
      h1 {
        font-size: 1.8rem;
      }
      .preview {
        font-size: 1rem;
      }
    }

    @media (max-width: 547px) {
      h1 {
        font-size: 1.6rem;
      }
      .preview {
        font-size: 0.9rem;
      }
    }
  }
`;

export const ContainerCard = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 20px;
  margin: 40px 80px;
  padding-bottom: 3rem;

  @media (max-width: 1760px) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    margin: 0 35px;
  }

  @media (max-width: 1268px) {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    margin: 0 35px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin: 0 35px;
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    margin: 0 35px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    margin: 0 20px;
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 0 25px;
  }

  @media (max-width: 360px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    margin: 0 25px;
  }
`;

export const Card = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 75%;
  object-fit: cover;

`;

export const CardContent = styled.div`
  height: 25%;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  border-top: 3px solid var(--bg-blue);

  h2 {
    color: #000;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .card-description {
    margin: 0;
    color: #000;
    margin-bottom: 5px;
    font-size: 1.2rem;
  }

  span {
    padding-top: 10px;
  }

  a {
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
    color: #1515aa;
    transition: transform 0.2s;

    &:hover {
      color: var(--bg-blue);
    }
  }

  .link {
    padding-left: 10px;
  }
`;
