import imgClient from "./imgClient.json";

// Import Swiper React components

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import { ContainerMain, Container } from "./styles";
import { useEffect, useState } from "react";

const Clientes = () => {
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 1100) {
        setSlidesPerView(2);
      } else if (window.innerWidth < 1600) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(5);
      }
    };

    window.addEventListener("resize", handleResize);
    // configurar o valor inicial
    handleResize();

    // remover o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ContainerMain>
      <Container>
        <h1>Nossos Clientes</h1>
        <p>Clientes que trabalham com a InnovaCode</p>

        <Swiper
          slidesPerView={slidesPerView}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Navigation]}
          spaceBetween={20}
          className="content"
        >
          {imgClient.map((item) => (
            <SwiperSlide key={item.id}>
              <img
                src={require(`../../assets/${item.image}`)}
                alt={item.title}
                className="custom-slide"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </ContainerMain>
  );
};

export default Clientes;
