import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContainerAbout = styled.div`
  background: var(--bg-blue-light);

`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  cursor: auto;
  user-select: text;

  &:hover {
    text-decoration: none;
  }
`;

export const ContainerMain = styled.section`
  cursor: auto;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;

  h1 {
    text-align: center;
    text-decoration: none;
    font-size: 2.5rem;
    padding-bottom: 3rem;
    color: var(--main);
    word-wrap: break-word;
    margin: 0 40px;
  }

  h2 {
    color: var(--main);
    padding-bottom: 2rem;
  }

  p {
    color: var(--main);
  }

  li {
    color: var(--main);
  }

  @media (max-width: 500px) {
    h1 {
    font-size: 2rem;

    }
  }

`;

export const ContainerSection = styled.div`
  padding-bottom: 3rem;
  max-width: 100%;
  margin: 0 40px;

  img {
    height: 400px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    filter: brightness(40%);
  }

  @media (max-width: 540px) {
    margin: 0 20px;
    
    img {
    height: 350px;

    }
    #value-img {
      height: 420px;

    }
  }

  @media (max-width: 400px) {
    #value-img {
      height: 470px;

    }
  }

`;

export const Mission = styled.div`
  position: relative;
  padding-bottom: 3rem;

`;

export const ContentMission = styled.div`
  position: absolute;
  left: 6%;
  top: 15%;
  width: 80%;

  @media (max-width: 540px) {
    top: 8%;
    width: 90%;
  }

`;


export const Vision = styled.div`
  position: relative;
  padding-bottom: 3rem;

  
`;


export const ContentVision = styled.div`
  position: absolute;
  left: 6%;
  top: 15%;
  width: 80%;

  @media (max-width: 540px) {
    width: 90%;
    top: 8%;

  }
`;

export const Values = styled.div`
  position: relative;

`;

export const ContentValues = styled.div`
  position: absolute;
  left: 6%;
  top: 15%;
  width: 80%;

  ol {
    padding-left: 30px;
  }

  li {
    padding-bottom: 10px;
  }

  @media (max-width: 540px) {
    width: 90%;
    top: 8%;

    ol {
    padding-left: 10px;
  }

  }
`;

export const ContainerAboutUs = styled.div`
  background: var(--bg-blue);
  width: 100%;
  padding-top: 3rem;
`;

export const AboutUs = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  width: 90%;
  padding: 0 2rem 3rem 0;
  background: var(--bg-blue);


  .content-aboutUs p {
    margin-bottom: 1rem;
  }
`;
