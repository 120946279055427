import styled from "styled-components";

export const ContainerSectionFocus = styled.section`
  width: 100%;
  background: var(--bg-blue-light);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;

  h1 {
    font-size: 2.3rem;
    color: var(--main);
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 965px) {
    width: 100%;
  }

  @media (max-width: 790px) {
    h1 {
      font-size: 1.8rem;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }


  @media (max-width: 547px) {
    h1 {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 440px) {
    text-align: center;
    h1 {
      margin-bottom: 2rem;
    }
  }
`;

export const ContentFocus = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
`;

export const ContentImage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  img {
    margin: 0 auto;
  }

  h2 {
    font-size: 1.3rem;
    color: var(--main);
    margin-top: 15px;
    word-break: initial;
    text-align: center;
    flex: 2;
  }

  p {
    display: flex;
    color: var(--main);
    margin: 15px;
    font-size: 1rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: flex;

    h2 {
      font-size: 1.3rem;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
    }

    img {
      width: 70px;
    }
  }
`;
