import React from 'react'
import Hero from "../../components/Hero"
import SectionFocus from '../../components/SectionFocus';
import Portfolio from '../../components/Portfolio';
import Clientes from '../../components/Clientes';
import Solutions from '../../components/Solutions';

import { Element } from 'react-scroll';

import { Global } from '../../Styles/global';

import destak from "../../assets/background.jpg";

const Home = () => {
  return (
    <>
        <Global/>
        <Element name="section-hero">
          <Hero 
            backgroundImage = {destak}
            title = "Transformando Desafios em Oportunidades com Soluções Inteligentes"
            description = "Descubra as soluções personalizadas da InnovaCode e impulsione o crescimento do seu negócio."
          />
        </Element>

        <Element>
          <SectionFocus/> 
        </Element>

        <Element name="section-portfolio">
          <Portfolio/>
        </Element>

        <Element>
          <Clientes/>
        </Element>

        <Element>
          <Solutions />
        </Element>

    </>
  )
}

export default Home;