import styled from "styled-components";

export const ContainerCopyright = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: var(--bg-blue);


  p {
      text-align: center;
      color: var(--main);
      font-size: 1.1rem;
  }
  
`;
