import React from 'react'

import { ContainerCopyright } from './styles';


const Copyright = () => {
  return (
    <ContainerCopyright>
         <p>InnovaCode &copy; 2023 | Todos os direitos reservados.</p>
    </ContainerCopyright>
  )
}

export default Copyright;