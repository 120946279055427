import React, { useState } from "react";
import Hero from "../../components/Hero";

import emailjs from 'emailjs-com';
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import MenuItem from "@mui/material/MenuItem";

import budgetImg from "../../assets/img/budget-hero.jpg";

import {
  ContainerBudget,
  Content,
  Container,
  Form,
  Description,
  CustomSelect,
  CustomButton,
  CustomBox,
  CustomTextField,
} from "./styles";


// const KEY_EMAILJS = process.env.REACT_APP_KEY_EMAILJS;
// const KEY_SERVICE = process.env.REACT_APP_KEY_SERVICE;
// const KEY_TEMPLATE = process.env.REACT_APP_KEY_TEMPLATE;


const formatPhoneNumber = (phoneNumber) => {

  const cleaned = String(phoneNumber).replace(/\D/g, "");

  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};


const Budget = () => {
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [option, setOption] = useState("Desenvolvimento de Site");
  const [message, setMessage] = useState("");


  function sendEmail (e) {
    e.preventDefault()

    if (name === "" || email === "" || cellPhone === "" || message === "") {
      // alert("Preencha todos os campos")
      toast.warn("Preencha todos os campos.")
      return;
    }

    // alert("Deu certo");
    toast.success("E-mail enviado com sucesso!")

    const templateParams = {
      from_name:  name,
      phone: cellPhone,
      option: option,
      email: email,
      message: message,
    }

    emailjs.send("service_rm6kxka", "template_quylfwj", templateParams, "h_MApjgH7ikSoc5kk")
    .then((response) => {
      console.log("RESPOSTA: ", response.status, response.text)
      setName("")
      setCellPhone("")
      setEmail("")
      setMessage("")
    }, (err) => {
      console.log("ERRO: ", err);
    })
  }
  

  return (
    <>
      <Hero
        backgroundImage={budgetImg}
        title="Faça seu orçamento"
        description="Informe-nos o que você necessita"
      />
      <ContainerBudget>
        <Content>
          <h1>Descreva no formulário abaixo o que você precisa</h1>

          <Container>
            <Description>
              <h2>Como solicitar um orçamento?</h2>

              <div>
                <h3>Precisa de sistemas web ou aplicativo móvel?</h3>
                <ul>
                  <li>
                    Descreva em detalhes as funcionalidades que imagina serem
                    necessárias{" "}
                  </li>
                  <li>
                    Quero um sistema ou aplicativo para fazer A, B, C, e que me
                    permita fazer X, Y, Z.{" "}
                  </li>
                </ul>
              </div>

              <div>
                <h3>Precisa de automação robótica/bot?</h3>
                <ul>
                  <li>
                    Descreva quais passos manuais gostaria de automatizar,
                    mencione sites e processos que precisa realizar.
                  </li>
                </ul>
              </div>

              <div>
                <h3>Precisa de relatórios e dashboards?</h3>
                <ul>
                  <li id="last-li">
                    Descreva as informações que precisa analisar de maneira
                    clara e concisa para tomada de decisões assertivas.{" "}
                  </li>
                </ul>
              </div>
            </Description>

            <Form>
              <CustomBox display="flex">
                <CustomTextField
                  className="custom-textField"
                  id="outlined-basic"
                  label="Nome *"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  variant="filled"
                  style={{marginRight: "10px"}}
                />
                <CustomTextField
                  className="custom-textField"
                  id="filled-basic"
                  type="tel"
                  label="Telefone *"
                  onChange={(e) => setCellPhone(e.target.value)}
                  value={formatPhoneNumber(cellPhone)}
                  variant="filled"
                />
              </CustomBox>

              <CustomTextField
                className="custom-textField"
                id="standard-basic"
                label="Email *"
                onChange={(e) => setEmail(e.target.value)}
                  value={email}
                variant="filled"
                fullWidth
                
              />

              <CustomSelect
                displayEmpty
                variant="filled"
                value={option}
                onChange={(e) => setOption(e.target.value)}
                style={{ width: "100%"}}
              >
                <MenuItem value="Desenvolvimento de Site">Desenvolvimento de Site</MenuItem>
                <MenuItem value="Desenvolvimento de Sistemas Web">Desenvolvimento de Sistemas Web</MenuItem>
                <MenuItem value="Desenvolvimento de Aplicativos">Desenvolvimento de Aplicativos</MenuItem>
                <MenuItem value="Automação de Processos / Bot">Automação de Processos / Bot</MenuItem>
                <MenuItem value="Relatórios e DashBoards">Relatórios e DashBoards</MenuItem>
              </CustomSelect>

              <CustomTextField
                className="custom-textField"
                id="outlined-multiline-basic"
                label="Mensagem *"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                variant="filled"
                multiline
                rows={5}
                fullWidth
                
              />

              <CustomButton
                fullWidth
                variant="contained"
                className="btn-send"
                type="submit"
                onClick={sendEmail}
              >
                Enviar
              </CustomButton>
            </Form>
          </Container>
        </Content>
      </ContainerBudget>
    </>
  );
};

export default Budget;
