import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import Budget from "../pages/Budget";
import Home from "../pages/Home/index";
import About from "../pages/About/index";
import Suport from "../pages/Suport/index";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { Element } from "react-scroll";
import Copyright from "../components/Copyright";

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/orcamento" element={<Budget />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/suporte" element={<Suport />} />

        <Route path="*" element={<RedirectDefault />} />
      </Routes>
      <Element name="section-footer">
        <Footer  key={"footer-key"} />
      </Element>
      <Copyright />
    </BrowserRouter>
  );
};

function RedirectDefault() {
  return <Navigate to="/"></Navigate>;
}

export default RoutesApp;
