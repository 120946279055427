import React from 'react'

import { ContainerSuport } from './styles';

import suportImg from "../../assets/img/suport-hero.jpg";

import Hero from "../../components/Hero";
import SuportForm from '../../components/SuportForm';

const Suport = () => {
  return (
    <ContainerSuport>
      <Hero
          backgroundImage={suportImg}
          title="Contate o nosso suporte"
          description={
            "Trabalhamos para manter a satisfação dos nossos clientes"
          }
        />
        <SuportForm />

    </ContainerSuport>
  );
};

export default Suport;