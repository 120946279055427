import styled from "styled-components";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export const ContainerBudget = styled.section`
  width: 100%;
  background: var(--bg-blue);
  padding: 3rem 0;
`;

export const Content = styled.div`
  padding: 20px;

  h1 {
    padding-top: 3rem;
    padding-bottom: 5rem;
    text-align: center;
    color: var(--main);
    font-size: 2.3rem;
  }

  @media (max-width: 500px) {
    h1 {
      display: flex;
      word-wrap: break-word;
      text-align: start;
      justify-content: flex-start;
      width: 95%;
      font-size: 1.85rem;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      display: flex;
      text-align: start;
      justify-content: flex-start;
      width: 100%;
      font-size: 1.5rem;
      margin-left: 10px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1106px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
  }
`;

export const Form = styled.form`
  width: 520px;
  border-radius: 5px;
  margin-left: 2rem;

  .custom-textField {
    background-color: #fff;
    border-radius: 5px;
  }

  @media (max-width: 1106px) {
    margin-left: 0;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
  }
`;

export const Description = styled.div`
  margin-left: 2rem;

  h2 {
    text-align: center;
    color: var(--main);
    font-size: 1.8rem;
    padding-bottom: 5px;
    text-align: start;
  }

  h3 {
    color: var(--main);
    padding-bottom: 5px;
  }

  div {
    padding-top: 10px;
  }

  ul {
    padding-left: 30px
  } 

  ul li {
    color: var(--text-boddy);
  }

  .btn-send {
    margin-top: 10px;
  }

  @media (max-width: 1106px) {
    margin-left: 0;
    width: 50%;

    #last-li {
      margin-bottom: 4rem;
    }
  }


  @media (max-width: 500px) {
    width: 90%;
    h2 {
      display: flex;
      text-align: start;
      justify-content: flex-start;
      width: 100%;
      font-size: 1.6rem;
    }
  }

  @media (max-width: 400px) {
    width: 95%;

    h2 {
      display: flex;
      text-align: start;
      justify-content: flex-start;
      width: 100%;
      font-size: 1.4rem;
    }

    h3 {
      display: flex;
      text-align: start;
      justify-content: flex-start;
      width: 100%;
      font-size: 1.1rem;
    }
  }
`;

export const CustomBox = styled(Box)`
  display: flex;
  width: 520px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CustomTextField = styled(TextField)`
  && {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    flex: 1;
  }
`;

export const CustomSelect = styled(Select)`
  margin-bottom: 10px;

  && {
    &.MuiInputBase-root {
      background-color: white;
      &:hover {
        background-color: white;
      }
      &:focus {
        background-color: white;
      }
    }
  }
`;

export const CustomButton = styled(Button)`
  && {
    background: linear-gradient(45deg, #1a8ce9 30%, #21cbf3 90%);
    border-radius: 3px;
    border: 0;
    color: white;
    height: 48px;
    padding: 0 30px;
    box-shadow: 0 3px 5px 2px rgba(33, 203, 243, 0.3);
  }
`;
