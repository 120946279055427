import React, { useState, useEffect } from "react";

import * as Scroll from 'react-scroll';
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { ContainerHeader } from "./styles";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setIsVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const scrollToAnchor = (scrollTo) => {
    closeMobileMenu();
    scroller.scrollTo(scrollTo, {
      duration: 2000,
      smooth: true,
    });
  };

  const goToHomeAndScroll = async (scrollTo) => {
    closeMobileMenu();
    await navigate('/');
    await scroller.scrollTo(scrollTo, {
      duration: 2000,
      smooth: true,
    });
  };

  const goToPage = async (to) => {
    closeMobileMenu();
    navigate(to);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <ContainerHeader className={`header ${isVisible ? "visible" : "hidden"}`}>
        <button onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={faBars} size="2xl" className="custom-icon" />
        </button>
        <h1>InnovaCode</h1>

        <ul className={isMobileMenuOpen ? "show" : "hidden"}>
          {location === '' ? (
            <li 
              className="links" 
              onClick={() => {
                scrollToAnchor('section-hero');
              }}
            >
              Home
            </li>
          ) : (
            <li 
              className="links" 
              onClick={() => {
                goToHomeAndScroll('section-hero');
              }}
            >
              Home
            </li>
          )}

          {location === '' ? (
            <li 
              className="links" 
              onClick={() => scrollToAnchor('section-portfolio')}
            >
              Portfolio
            </li>
          ) : (
            <li 
              className="links" 
              onClick={() => goToHomeAndScroll('section-portfolio')}
            >
              Portfolio
            </li>
          )}
          <li 
            className="links" 
            onClick={() => goToPage("/sobre")}
          >
            Sobre
          </li>

          <li 
            className="links" 
            onClick={() => scrollToAnchor("section-footer")}
          >
            Contato
          </li>
          
          <li
            className="links" 
            onClick={() => goToPage("/orcamento")}
          >
            Orçamento
          </li>
          <li
            className="links" 
            onClick={() => goToPage("/suporte")}
          >
            Suporte
          </li>
        </ul>

        <div></div>
      </ContainerHeader>
    </>
  );
};

export default Header;
