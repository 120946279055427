import React from "react";


import { ContaineSolutions, Content, Card } from "./styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faCode, faLaptopCode, faMobileScreen, faRobot } from "@fortawesome/free-solid-svg-icons";



const Solutions = () => {
  return (
    <ContaineSolutions>
      <h1>Soluções</h1>
      <p className="info">Confira as áreas de atuação da InnovaCode</p>
      <Content>
        <Card>
          <FontAwesomeIcon icon={faLaptopCode} size="xl" className="icon-card" />
          <h4>Desenvolvimento de sites</h4>
          <p className="description">
          Desenvolvemos websites personalizados e responsivos projetados para impulsionar sua marca e criar uma conexão sólida com seu público.
          </p>
        </Card>

        <Card>
          <FontAwesomeIcon icon={faCode} size="xl" className="icon-card" />
          <h4>Desenvolvimento de sistemas WEB</h4>
          <p className="description">
          Criação de sistemas web personalizados para o seu negócio. Melhore a eficiência e o gerenciamento de processos.
          </p>
        </Card>

        <Card>
          <FontAwesomeIcon icon={faMobileScreen} size="xl" className="icon-card" />
          <h4>Desenvolvimento de Aplicativos</h4>
          <p className="description">
          Desenvolvemos aplicativos móveis personalizadas para atender às necessidades específicas de cada cliente.
          </p>
        </Card>

        <Card>
          <FontAwesomeIcon icon={faRobot} size="xl" className="icon-card" />
          <h4>RPA</h4>
          <p className="description">
          Automatização de tarefas e processos rotineiros por meio de robôs programados. Reduza erros, economize tempo e recursos.
          </p>
        </Card>

        <Card>
          <FontAwesomeIcon icon={faChartColumn} size="xl" className="icon-card" />
          <h4>Desenvolvimento de relatórios e dashboards</h4>
          <p className="description">
          Criação de relatórios e dashboards personalizados para análise de dados e tomada de decisões informadas.
          </p>
        </Card>
      </Content>
    </ContaineSolutions>
  );
};

export default Solutions;
