import React, { useState } from "react";
import {
  ContainerSuportForm,
  ContentSuportForm,
  CustomBox,
  CustomButton,
  CustomSelectSuport,
  CustomTextField,
} from "./styles";
import { FormControl, MenuItem } from "@mui/material";

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import emailjs from 'emailjs-com';

// const KEY_EMAILJS = process.env.REACT_APP_KEY_EMAILJS;
// const KEY_SERVICE = process.env.REACT_APP_KEY_SERVICE;
// const KEY_TEMPLATE = process.env.REACT_APP_KEY_TEMPLATE;

const optionsSelect = [
  "Sites web",
  "Aplicativos móveis",
  "Sistemas web",
  "Automação Robótica",
  "Relatórios e DashBoards",
];

const SuportForm = () => {
  const [name, setName] = useState("");
  const [valueSelect, setValueSelect] = useState(optionsSelect[0]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleChangeSelect = (event) => {
    setValueSelect(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault()

    if (name === "" || email === "" || message === "") {
      toast.warn("Preencha todos os campos")
      return;
    }
    toast.success("E-mail enviado com sucesso!")


    const templateParams = {
      from_name:  name,
      option: valueSelect,
      email: email,
      message: message,
    }

    emailjs.send("service_rm6kxka", "template_quylfwj", templateParams, "h_MApjgH7ikSoc5kk")
    .then((response) => {
      console.log("RESPOSTA: ", response.status, response.text)
      setName("")
      setEmail("")
      setMessage("")
      setValueSelect(optionsSelect[0])
    }, (err) => {
      console.log("ERRO: ", err);
    })
  }

  return (
    <ContainerSuportForm>
      <div className="content-title-suport">
        <h1>Descreva suas dúvidas</h1>
        <p className="info">
          Forneça detalhes abrangentes sobre suas dúvidas no formulário, e nós
          responderemos o mais rápido possível. Estamos aqui para ajudar!
        </p>
      </div>
      <ContentSuportForm>
        <FormControl fullWidth>
          <CustomBox display="flex">
            <CustomTextField
              id="suport-textField-name-id"
              label="Nome *"
              variant="filled"
              value={name}
              onChange={handleChangeName}
              style={{ flex: 1, marginRight: 10 }}
            />

            <CustomSelectSuport
              id="suport-select-name-id"
              displayEmpty
              variant="filled"
              value={valueSelect}
              onChange={handleChangeSelect}
            >
              {
                optionsSelect.map(option => 
                  <MenuItem value={option}>{option}</MenuItem>
                )
              }
            </CustomSelectSuport>
          </CustomBox>

          <CustomTextField
            id="standard-basic"
            label="Email *"
            variant="filled"
            fullWidth
            value={email}
            onChange={handleChangeEmail}
          />

          <CustomTextField
            id="outlined-multiline-basic"
            label="Messagem *"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            value={message}
            onChange={handleChangeMessage}
          />
          <CustomButton
            fullWidth
            variant="contained"
            className="btn-send"
            type="submit"
            onClick={sendEmail}
          >
            Enviar
          </CustomButton>
        </FormControl>
      </ContentSuportForm>
    </ContainerSuportForm>
  );
};

export default SuportForm;
