import styled from "styled-components";



export const ContainerHero = styled.div`
  position: relative;
  height: 500px;
  max-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 500px;
    display: block;
    object-fit: cover;
    filter: brightness(50%);
  }
  

  @media (max-width: 768px) {
    height: 400px;

    img {
      height: 100%;
    }

  }

  @media (max-width: 440px) {
    height: 380px;
  }
`;





export const ContentHero = styled.div`
  position: absolute;
  top: 10%;
  left: 2%;
  display: inline-block;
  width: 700px;

  h1 {
    font-size: 3rem;
    margin-top: 3rem;
    color: var(--main);
    margin-left: 30px;
    margin-bottom: 2rem;
    word-break: initial;
  }

  p {
    color: var(--main);
    margin-left: 30px;
    margin-bottom: 5rem;
    width: 400px;
    font-size: 1.1rem;
  }

  button {
    padding: 10px 40px;
    background: var(--btn);
    color: var(--main);
    font-weight: 500;
    font-size: 1.2rem;
    border-radius: 30px;
    margin-bottom: 3rem;
    border: none;
    cursor: pointer;
    margin-left: 30px;

    transition: 0.2s ease;

    &:hover {
      filter: brightness(0.9);
    }

    &#transparent {
      background: transparent;
      border: 1px solid var(--main);
    }
  }

  @media (max-width: 768px) {
    width: 500px;

    h1 {
      word-wrap: break-word;
      font-size: 2.2rem;
    }

    p {
      margin-bottom: 2.9rem;
    }

    button {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        padding: 10px 40px;
        font-size: 1.1rem;
        border-radius: 30px;
        border: none;
        cursor: pointer;
        margin-left: 30px;
    }

  }


  @media (max-width: 510px) {

    width: 400px;
    top: 16%;

    h1 {
      word-wrap: break-word;
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1rem;
      width: 370px;
    }

    .btns {
        display: flex;
        justify-content: flex-start;    
    }

    button {
        width: 100px;
        margin-left: 30px;
        font-size: 0.9rem;
        padding: 10px;
        margin-bottom: 1rem;
    }
  }
  @media (max-width: 480px) {
    width: 400px;

    h1 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 450px) {
    width: 400px;
    left: 0%;

    h1 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 410px) {
    width: 400px;

    h1 {
      font-size: 1.6rem;
    }
    p {
      font-size: 0.9rem;
      width: 310px;
    }
  }

  @media (max-width: 360px) {
    width: 360px;

    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.9rem;
      width: 310px;
    }
  }
`;
