import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`

    :root {
        --bg-blue: #00002B;
        --bg-blue-light: #111036;

        --main: #ffffff;

        --text-boddy: #939393;

        --btn: #23A6F0;
    }


    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Poppins, sans-serif;
    }

`;
