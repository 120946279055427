import styled from "styled-components";

export const ContainerHeader = styled.header` 

  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
 
  &.visible {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(-80px);
  }


  display: flex;
  border: 1px solid black;
  height: 80px;
  width: 100%;
  background: var(--bg-blue);
  align-items: center;
  justify-content: space-between;

  h1 {
    color: var(--main);
    margin-left: 30px;
    display: inline-block;
  }

  @media (max-width: 768px) {

    h1 {
      margin-left: 0px;
    }
  }

 

  ul {
    display: flex;
    text-align: center;

    @media (max-width: 930px) {
      display: flex;
      flex-direction: column;
      background: var(--bg-blue);
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      z-index: 1;
      text-align: left;
      padding: 10px 0 20px 20px;

      &.show {
        display: flex;
      }

      &.hidden {
        display: none;
      }

      li {
        margin-top: 10px;
      }
    }
  }

  li {
    list-style: none;
  }

  .links {
    color: var(--main);
    margin-right: 30px;
    text-decoration: none;
    font-size: 1.1rem;
    cursor: pointer;

    transition: 0.2s ease;

    &:hover {
      filter: brightness(0.7);
    }
  }

  div {
    margin-left: 30px;
  }

  button {
    display: none;
    border: none;
    margin-left: 10px;

    @media (max-width: 930px) {
      display: block;
    }

    & .custom-icon {
      color: #fff;
      background: var(--bg-blue-light);
      cursor: pointer;
      font-size: 2rem;

      transition: 0.2s;

      &:hover {
        filter: brightness(0.7);
      }
    }
  }
`;
