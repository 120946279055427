import React from "react";
import cardsData from "./cardsData.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContainerCard, CardImage, CardContent, Card, ContentPortfolio } from "./styles";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Portfolio = () => {
  return (
    <ContentPortfolio>
        <div>
          <h1>
            Nossos Projetos
          </h1>
          <p className="preview">
            Aqui você poderá visualizar alguns projetos desenvolvidos pela InnovaCode
          </p>
        </div>
      <ContainerCard>
        {cardsData.map((card) => (
          <Card key={card.id}>
            <CardImage src={require(`../../assets/${card.image}`)} alt={card.title} />
            <CardContent>
              <h2>{card.title}</h2>
              <p className="card-description">{card.description}</p>
              <span>
                <a 
                  href={card.link}
                  rel="noreferrer"
                  target="_blank"
                >Visitar
                  <FontAwesomeIcon icon={faLink} className="link" /> 
                </a>
              </span>
            </CardContent>
          </Card>
        ))}
      </ContainerCard>
    </ContentPortfolio>
  );
};

export default Portfolio;
